'use client';

import { Box, Button, Flex, Icon, Text } from '@ui/components';
import { Link } from '@ui/components/link';
import { useRouter } from 'next/navigation';
import { errorMessages } from 'packages/constants/src';
import { startTransition } from 'react';

import { messageWrapper } from '../../app/product-lookup/product-lookup.css';

export default function ErrorMessage({ error, reset }: { error: Error & { digest?: string }; reset?: () => void }) {
  const router = useRouter();
  const { title, body, link } =
    errorMessages[(error.message && error.message === 'noPlan' ? 'noPlan' : 'generic') ?? 'generic'];
  return (
    <Flex className={messageWrapper}>
      <Flex gap="space-b">
        <Icon color="error" name="warning" />
        <Flex flexDirection="column" rowGap="space-b">
          {error && title && (
            <>
              <Text color="error" style="body1" weight="bold">
                {title}
              </Text>
              {body && (
                <Text color="contrast" display="inline-flex" gap="space-a" style="body2">
                  {body}
                  {link && (
                    <Link href={link.link} style={{ textDecoration: 'underline' }} target="_blank">
                      {link.title}
                    </Link>
                  )}
                </Text>
              )}
            </>
          )}
          <Box>
            <Button
              color="contrast"
              icon={<Icon name="arrow_upward_alt" />}
              label={'Search again'}
              onClick={() => {
                // to make the reset works: https://github.com/vercel/next.js/discussions/45829
                const url = new URL(window.location.href);
                router.push(url.origin + url.pathname);
                if (reset) startTransition(() => reset());

                const addresslookupArea = document.getElementById('address-lookup');
                if (addresslookupArea) {
                  addresslookupArea.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              type="tertiary"
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
