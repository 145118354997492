import type { SiteMapEntry } from './sitemap';

interface ErrorMessage {
  title: string;
  body: JSX.Element | string;
  link?: SiteMapEntry;
}

export const errorMessages: Record<string, ErrorMessage> = {
  promo: {
    title: `Looks like that promo code isn’t right.`,
    body: 'Try again or if it keeps happening, ',
    link: { title: 'contact us', link: '/contact-us' },
  },
  generic: {
    title: 'Looks like something went wrong.',
    body: 'If it keeps happening, ',
    link: { title: 'contact us', link: '/contact-us' },
  },
  noPlan: {
    title: 'Oops! Something’s not quite right.',
    body: (
      <div>
        <p>Please try again in a few minutes.</p>
        <br></br>
        <p>If it's still not working our customer service team can help you.</p>
        <br></br>
        <div>
          Give us a call on 1800 750 750 or{' '}
          <a href="/contact-us" style={{ textDecoration: 'underline' }} target="_blank">
            contact us here
          </a>{' '}
          and we’ll get in touch.
        </div>
        <br></br>
        <p>If you’re transferring from another retailer, have your last bill handy to speed up the process.</p>
      </div>
    ),
  },
};
