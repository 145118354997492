'use client'; // Error components must be Client Components
import { Container } from '@ui/components';
import { useEffect } from 'react';

import ErrorMessage from '~/components/products/error';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error('error route: ', { error });
  }, [error]);

  return (
    <Container>
      <ErrorMessage error={{ message: 'generic', name: 'generic' }} reset={reset} />
    </Container>
  );
}
